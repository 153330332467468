import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { junkRemovalRoutes, movingRoutes, laborRoutes } from 'common/routing/AppRoute';
import { MovingIcon } from 'common/assets/icons/MovingIcon';
import { JunkRemovalIcon } from 'common/assets/icons/JunkRemovalIcon';
import { LaborIcon } from 'common/assets/icons/LaborIcon';
import { Container } from 'common/app/home/container/Container';
// import { CallMeNowModalContainer } from 'common/app/callMeNowModal/CallMeNowModalContainer';
import { useResetState } from 'common/hooks/useResetState/useResetState';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { useStyles } from './Home.styles';
import { ServiceCard } from './serviceCard/ServiceCard';
import { AboutHunks } from './aboutHunks/AboutHunks';
import { WhyHunks } from './whyHunks/WhyHunks';
import { HappyCustomers } from './happyCustomers/HappyCustomers';
import { InTheCommunity } from './inTheCommunity/InTheCommunity';
import { PartnerHero } from './partnerHero/PartnerHero';

const serviceBoxesData = (partnerName?: string) => {
  const isPlaceForMom = partnerName === process.env.REACT_APP_PARTNER_NAME;

  return [
    {
      icon: <MovingIcon />,
      title: isPlaceForMom ? 'Full Service Moving' : 'Moving',
      subTitle: isPlaceForMom
        ? 'Options to meet the needs of each transition; done with a focus on Trust, Care and the utmost in Safety'
        : 'Let us do the heavy lifting so you can move into your home with ease.',
      route: movingRoutes.step5,
    },
    {
      icon: <JunkRemovalIcon />,
      title: isPlaceForMom ? (
        'Downsizing Support'
      ) : (
        <>
          Junk removal /
          <br />
          donation pickup
        </>
      ),
      subTitle: isPlaceForMom
        ? 'Mindful and compassionate donation delivery, storage, repurposing and disposal of furniture and other items that are not to be moved into the new home.'
        : 'Declutter your home in an eco-friendly way with the H.U.N.K.S.',
      route: junkRemovalRoutes.step2,
    },
    {
      icon: <LaborIcon />,
      title: isPlaceForMom ? 'Hourly services' : 'Labor (other)',
      subTitle: isPlaceForMom
        ? "Need a hand? We're happy to pack or lend some muscle to get fully set up."
        : "Need a hand? We're happy to pack or lend some muscle within your home.",
      route: laborRoutes.step2,
    },
  ];
};

export const Home = () => {
  const styles = useStyles();
  const history = useHistory();
  const { isPartner, partnerData } = useFormState();
  const resetState = useResetState();

  const handleServiceClick = useCallback(
    (route: string) => {
      resetState();
      history.push(route);
    },
    [resetState, history],
  );

  return (
    <div data-testid="home">
      {isPartner && <PartnerHero />}

      <Container id="service-boxes" className={styles.serviceBoxesContainer}>
        <Typography variant="h3" className={styles.title}>
          {isPartner && 'Select your service below to get started:'}
          {!isPartner && (
            <>
              Select your service below and get a free estimate from{' '}
              <span className={styles.highlightText}>college H.U.N.K.S.</span>
            </>
          )}
        </Typography>

        <div className={styles.serviceBoxes}>
          {serviceBoxesData(partnerData?.partnerName).map(({ icon, title, subTitle, route }) => (
            <ServiceCard
              key={route}
              icon={icon}
              title={title}
              subTitle={subTitle}
              onClick={() => handleServiceClick(route)}
            />
          ))}
        </div>
      </Container>

      {isPartner && (
        <>
          <WhyHunks />
          <AboutHunks />
          <HappyCustomers />
          <InTheCommunity />
        </>
      )}

      {/* TODO CALLMNOW: modal hidden for now */}
      {/* <CallMeNowModalContainer /> */}
    </div>
  );
};
